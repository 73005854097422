import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { HeaderHero, NavBarColorSwitcher, Spacer, WrapperBody } from "components";
export const _frontmatter = {
  "title": "Website audit",
  "navBarColor": "light"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <HeaderHero title="Website audit" subtitle="Get an insight from our specialist" button={{
      children: "Apply now",
      to: "/audit"
    }} mdxType="HeaderHero" />
    <WrapperBody mdxType="WrapperBody">
	<NavBarColorSwitcher color="dark" mdxType="NavBarColorSwitcher">
		<h2>You're amazing</h2>
		<p>And we already got your submission in our inbox.</p>
		<p>
			Please, hold tight while Anna processes your request. You'll get the
			reply shortly.
		</p>
	</NavBarColorSwitcher>
    </WrapperBody>
    <Spacer height={180} responsive={{
      md: 140,
      sm: 100
    }} mdxType="Spacer" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      